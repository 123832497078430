.dataTableContainer {
    margin: 5vh 5vw;
    /* margin-top: 100px; */
}

.dataTableContainer th {
    background-color: lightblue;
}

.dataTableContainer th.jobHeader {
    background-color: lightgray;
}

.dataTableContainer th.nameHeader {
    background-color: transparent;
}

.dataTableContainer td.otCell {
    background-color: rgba(255, 215, 0, 0.1);
}