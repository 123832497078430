.uploaderContainer {
    align-items: center;
    border: 1px solid black;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    height: 40px;
    justify-content: center;
    left: 10px;
    position: absolute;
    top: 10px;
    width: 40px;
}

.uploaderContainer:hover {
    background-color: #bfbfbf;
}

.uploaderContainer i {
    margin: 0;
}